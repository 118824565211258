var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("LoadingCell")
        : _vm.error
        ? _c("BIconExclamationCircle", {
            staticClass: "text-danger",
            attrs: { title: _vm.error }
          })
        : _vm.overview
        ? [
            _c("div", [
              _vm._v(
                "Match: " +
                  _vm._s(_vm.percentage(_vm.overview.matchPositionsPercentage))
              )
            ]),
            _c("div", [
              _vm._v(
                "Best #" +
                  _vm._s(_vm.overview.bestRound.round_num + 1) +
                  ": " +
                  _vm._s(_vm.percentage(_vm.overview.bestRound.perc))
              )
            ])
          ]
        : [_vm._v("-")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }